import TabsController from "./tabs_controller"

export default class DynamicTabsController extends TabsController {
  connect() {
    this._init();
  }

  _init() {
    if(this.triggerTargets.length > 0) {
      this.openTab(this.triggerTargets[0].dataset.index);
    }
    this._initTriggerEvents();

    if (isDevEnvironment()) {
      console.info(`[DEV] Dynamic Tabs Initialized`);
    }
  }


}

import { Controller } from "@hotwired/stimulus"
import Splide from "@splidejs/splide";

export default class MenuController extends Controller {
  // a random comment
  static targets = ["tabButtons", "tabPanels"];

  connect() {
    this._init();
  }

  initialize() {
    this.buttons = null;
    this.panels = null;
  }

  _init() {
    this._initTabs();
    this._initPanels();
    this._initCarousels();

    if (isDevEnvironment()) {
      console.info(`[DEV] Food Menu Initialized`);
    }
  }

  _initTabs() {
    this.buttons = new Splide(this.tabButtonsTarget, {
      gap: '1.5rem',
      pagination: false,
      arrows: false,
      isNavigation: true,
      autoWidth: true,
      trimSpace: true,
      focus: 0,
    });
  }

  _initPanels() {
    this.panels = new Splide(this.tabPanelsTarget, {
      type: 'fade',
      height: '100%',
      pagination: false,
      arrows: false,
      isNavigation: false,
      autoWidth: false,
      trimSpace: false,
      focus: 0,
      destroy: false,
      drag: false,
    });
  }

  _initCarousels() {
    this.panels.sync(this.buttons);
    this.panels.mount();
    this.buttons.mount();
  }
}

import { Controller } from "@hotwired/stimulus"

export default class HeaderController extends Controller {
  static targets = ["burgerButton", "burgerMenu"];
  
  connect() {
    this._init();
  }

  _init() {
    this.burgerButtonTarget.addEventListener("click", (event) => {
      event.preventDefault();
      if (this.burgerMenuTarget.classList.contains("is-open")) {
        this.closeMenu();
      } else {
        this.openMenu();
      }
    });

    if (isDevEnvironment()) {
      console.info(`[DEV] Header Initialized`);
    }
  }

  openMenu() {
    document.body.classList.add("has-menu-open");
    this.burgerMenuTarget.classList.add("is-open");
    this.burgerMenuTarget.focus();
    this.burgerButtonTarget.classList.add("is-active");
    this.burgerButtonTarget.setAttribute("aria-expanded", "true");
  }

  closeMenu() {
    document.body.classList.remove("has-menu-open");
    this.burgerMenuTarget.classList.remove("is-open");
    this.burgerMenuTarget.blur();
    this.burgerButtonTarget.classList.remove("is-active");
    this.burgerButtonTarget.setAttribute("aria-expanded", "false");
  }
}

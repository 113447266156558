import { Controller } from "@hotwired/stimulus"
import { Splide } from "@splidejs/splide";
export default class LocationsController extends Controller {
  static targets = ["thumbnails", "slider", "map", "item"];
  connect() {
    this._init();
  }
  initialize() {
    this.thumbnails = null;
    this.slider = null;
    this.map = null;
    this.marker = null;
  }
  _init() {
    this.slider = null;
    this.thumbnails = null;
    this._setupThumbnails();
    this._setupSlider();
    this._setupMap();
    this._initCarousel();

    if (isDevEnvironment()) {
      console.info(`[DEV] Locations Initialized`);
    }
  }
  _setupThumbnails() {
    this.thumbnails = new Splide(this.thumbnailsTarget, {
      type: "loop",
      perPage: 3,
      perMove: 1,
      gap: '1.75rem',
      pagination: false,
      arrows: false,
      isNavigation: true,
      breakpoints: {
        992: {
          perPage: 2,
        },
        668: {
          perPage: 1,
        },
      },
    });
  }
  _setupSlider() {
    this.slider = new Splide(this.sliderTarget, {
      type: "fade",
      perPage: 1,
      perMove: 1,
      pagination: false,
      arrows: false,
      drag: false,
    });


    this.slider.on('active', (slide) => {
      if (this.map && this.marker) {
        const latitude = slide.slide.dataset.lat;
        const longitude = slide.slide.dataset.lng;
        const position = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
        this._moveMarker(position);
      }
    });
  }

  _initCarousel() {
    this.slider.sync(this.thumbnails);
    this.slider.mount();
    this.thumbnails.mount();
  }

  async _setupMap() {
    const firstItem = this.itemTargets[0];
    const latitude = firstItem.dataset.lat;
    const longitude = firstItem.dataset.lng;
    const position = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

    var locations = JSON.parse(this.mapTarget.dataset.array);

    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
    const mapId = this.mapTarget.dataset.id;

    this.map = new Map(this.mapTarget, {
      zoom: 16,
      center: position,
      mapId: mapId,
    });

    locations.forEach((i) => {
      const parser = new DOMParser();
      const pinSvgString = `
      <svg width="40" height="60" viewBox="0 0 40 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 0C8.97461 0 0 8.97461 0 20C0 37.6416 18.2861 58.6816 19.0625 59.5703C19.3018 59.8438 19.6387 60 20 60C20.3857 59.9756 20.6982 59.8438 20.9375 59.5703C21.7139 58.667 40 37.2656 40 20C40 8.97461 31.0254 0 20 0ZM20 13.75C24.1406 13.75 27.5 17.1094 27.5 21.25C27.5 25.3906 24.1406 28.75 20 28.75C15.8594 28.75 12.5 25.3906 12.5 21.25C12.5 17.1094 15.8594 13.75 20 13.75Z" fill="#FF830F"/>
      </svg>
      `;
      const pinSvg = parser.parseFromString(
        pinSvgString,
        "image/svg+xml",
      ).documentElement;

      const marker = new AdvancedMarkerElement({
        position: {lat: i.lat, lng: i.lng},
        map: this.map,
        title: `Umami`,
        content: pinSvg,
      });
  

      marker.addListener("click", ({ domEvent, latLng }) => {
        const { target } = domEvent;
        // Ovdje bi trebalo aktivirat Thumbnail
        
        
      });
      this.marker = marker;
    });
  }

  _moveMarker(position) {
    this.marker.position = position;
    this.map.setZoom(16);
    this.map.panTo(position);
  }
}

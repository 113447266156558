import { Controller } from "@hotwired/stimulus"
import { Splide } from "@splidejs/splide";

export default class ShowcaseController extends Controller {
  static targets = ["slider"];

  connect() {
    this._init();
  }
  
  initialize() {
    this.slider = null;
  }

  _init() {
    this._initSlider();

    if (isDevEnvironment()) {
      console.info(`[DEV] Showcase Initialized`);
    }
  }

  _initSlider() {
    this.slider = new Splide(this.sliderTarget, {
      type: "loop",
      perPage: 3,
      perMove: 1,
      pagination: false,
      arrows: true,
      gap: "1.75rem",
      classes: {
        arrows: "splide__arrows home-showcase__arrows",
        arrow: "splide__arrow home-showcase__arrow button is-outlined",
        prev: "splide__arrow--prev is-prev",
        next: "splide__arrow--next is-next",
      },
      arrowPath: 'M29 40L8.99997 20L29 -2.98678e-07L30.3676 1.36759L11.7351 20L30.3676 38.6324L29 40Z',
      breakpoints: {
        1312: {
          perPage: 2,
        },
        668: {
          perPage: 1,
        },
      },
    });

    this.slider.mount();
  }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

import I18n from 'i18n-js'
window.I18n = I18n

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Stimulus = Application.start()
const sharedContext = require.context("./controllers/shared", true, /\.js$/)
Stimulus.load(definitionsFromContext(sharedContext))

const applicationContext = require.context("./controllers/application", true, /\.js$/)
Stimulus.load(definitionsFromContext(applicationContext))

require("./custom/loading");
require("./custom/modal");
require("./custom/image_upload_preview");
require('./custom/utilities');
require('./custom/notifications');
require("./custom/confirmation_dialog");
require("./custom/remote_links_fixer");
require("./custom/server_notice");

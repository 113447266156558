import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js";

export default class LanguageController extends Controller {
  static targets = [ "mobile", "desktop" ]

  initialize() {
    this.choice = null;

    this._init();
  }

  _init() {
    this.choice = new Choices(this.desktopTarget, {
      searchEnabled: false,
      itemSelectText: '',
      classNames: {
        containerOuter: 'choices language-select--desktop',
      },
    });

    this.element.addEventListener('change', (event) => {
      if (event.detail.value) {
        window.location = event.detail.value;
      }
    });

    if (isDevEnvironment()) {
      if (this.choice) {
        console.info(`[DEV] Language picker initialized: `, this.element);
      }
      else {
        console.error(`[DEV] Error in initialization of Language picker: `, this.element);
      }
    }
  }
}
